import React, { useState } from "react";
import Navbar from './Navbar';

const About = () => {

  const [logoSrc, setLogoSrc] = useState(process.env.PUBLIC_URL + "/img/github.svg");

  const handleMouseEnter = () => {
    setLogoSrc(process.env.PUBLIC_URL + "/img/pinkGithub.svg");
  };

  const handleMouseLeave = () => {
    setLogoSrc(process.env.PUBLIC_URL + "/img/github.svg");
  };
 
  return (
    <>
        <Navbar/>  

        <p className='aboutText'>
            Hello guys, I'm Clément 📍🇫🇷. <br/><br/>  
            As of september 2025, I will be on my last year of a Master’s Degree in Software Architecture.<br/>  
            Passionate about Software Development and technology, I am focused on building efficient, scalable applications.<br/>  
            In my spare time, I enjoy playing the saxophone, practicing street workout, and reading science fiction books !<br/><br/><br/>  
         
            Here are some of my skills <br/><br/>  
            Languages: PHP, Java, JavaScript, TypeScript  <br/>  
            Frameworks & Libraries: Laravel 11, Spring Boot, ReactJS, NodeJS, ExpressJS, Slim<br/>  
            Database: SQL, MySQL, NoSQL, PostGreSql<br/>  
            Tools: Docker, Git, Postman, MAMP, SSMS, IIS<br/>  
            Methodologies: SCRUM, DevOps (CI/CD)
            <br/> 
            <br/>
            <br/>    

            Languages <br/><br/>  

            French - Mother tongue<br/>  
            English – Proficient <br/>  
            Spanish - School level<br/>   
            Japanese - Few words<br/>  
            Mandarin - Few words<br/>  
        </p>

        <br/>

        <a href="https://github.com/clemzouuu" target="_blank">  
          <img 
              src={process.env.PUBLIC_URL + logoSrc} 
              className="logoGithub" 
              alt="Logo"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
          />
        </a> 

        <br/>
        <br/>
        <br/>

        <img 
            src={process.env.PUBLIC_URL + '/img/nerd-emoji-nerd.gif'} 
            className="nerdEmoji" 
            alt="nerd emoji"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        />
       
    </>
  );
};

export default About;
